import { useModal } from 'components/modal/Modal'
import { css } from '@emotion/react'
import { Icon } from 'components/icon'
import { colors } from 'utils/colors'
import { BREAKPOINTS } from 'utils/styled'
import { HStack } from 'components/Stack'
import { Button } from 'components/button/Button'
import styled from '@emotion/styled'

const StyledIFrameContainer = styled.span`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    border: none;
  }
`
const VideoModal = ({ closeAction, videoId }: { closeAction: () => void; videoId: string }) => (
  <div>
    <HStack
      css={css`
        justify-content: flex-end;
        padding-bottom: 1.25rem;
      `}
    >
      <Button
        type="button"
        variant="text"
        onClick={() => {
          closeAction()
        }}
      >
        <Icon name="Close" color={colors.white} />
      </Button>
    </HStack>
    <StyledIFrameContainer>
      <iframe allowFullScreen src={`https://www.youtube.com/embed/${videoId}`} />
    </StyledIFrameContainer>
  </div>
)

export const useVideoModal = (videoId?: string) => {
  const modalConfig = {
    modalCloseButtonCss: css`
      display: none;
    `,
    modalCss: css`
      padding: 0;
      max-width: 80%;
      @media ${BREAKPOINTS.MD.max} {
        max-width: 100%;
      }
    `,
    modalContentCss: css`
      padding: 0;
      box-shadow: none;
      background-color: transparent;
    `,
  }

  const [modal, openModal] = useModal(
    ({ isOpen, close }) =>
      isOpen && videoId ? <VideoModal closeAction={close} videoId={videoId} /> : null,
    modalConfig
  )
  return [modal, openModal] as const
}
