import styled from '@emotion/styled'
import { Title, Title_h3_default } from 'components/Typography'
import { Maybe } from 'graphql/jsutils/Maybe'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
export const STitle = styled(Title)`
  text-align: left;

  white-space: pre-line;

  @media ${BREAKPOINTS.LG.max} {
    font-size: ${pxToRemUnit(32)};
  }

  @media ${BREAKPOINTS.MD.max} {
    text-align: center;
    align-self: center;
    justify-self: center;
    font-size: ${pxToRemUnit(36)};
  }
`

export function LandingPageHeroTitle(props: { title: Maybe<string>; className?: string }) {
  return (
    <STitle variant={Title_h3_default} as={'h1'} className={props.className}>
      {props.title}
    </STitle>
  )
}
