import { css } from '@emotion/react'
import { Button } from 'components/button/Button'
import { ButtonProps, ButtonTone } from 'components/button/types'
import { FC } from 'react'
import { BREAKPOINTS } from 'utils/styled'

/** Full width on mobile */
export const LandingPageTextImageButton: FC<
  React.PropsWithChildren<{ tone?: ButtonTone; className?: string } & Omit<ButtonProps, 'type'>>
> = (props) => {
  return (
    <Button
      type="button"
      renderAsButton
      css={css`
        @media ${BREAKPOINTS.MD.min} {
          align-self: flex-start;
        }
      `}
      {...props}
    >
      {props.children}
    </Button>
  )
}
