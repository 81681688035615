import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { minSpace, spaceLarge, spaceSmall } from 'components/Block'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
import { Stack } from 'components/Stack'

/** Mobile images are clipped to overflow color background.
 *  It was arranged the images will be clipped 84px from the top for the smallest mobile view (360px)
 */
const getClipHeight = (pageWidth: number) => {
  const orgImageClipHeight = 84
  const orgImageWidth = 360
  // cropped picture often has translucent edges
  const visualImprecisionFix = 2

  return Math.ceil((pageWidth * orgImageClipHeight) / orgImageWidth) + visualImprecisionFix
}

export const SContainer = styled.section<{
  imagePlacement: 'left' | 'right'
  titlePlacement?: 'above' | 'below'
  backgroundColor?: string
  width: number
  imageOverflow: boolean
  fillMobileBackground?: boolean
}>`
  display: grid;
  column-gap: 3rem;
  overflow: hidden;
  min-height: 360px;
  background: ${({ backgroundColor }) => backgroundColor};
  align-items: start;
  overflow: ${({ imageOverflow }) => (imageOverflow ? 'visible' : 'unset')};

  .title {
    grid-area: title;

    @media ${BREAKPOINTS.MD.max} {
      padding: 0 ${minSpace};
      margin-bottom: 0;
    }
  }
  ${({ imagePlacement }) =>
    imagePlacement === 'left'
      ? css`
          grid-template:
            'image .' minmax(2rem, 4fr)
            'image icon'
            'image title'
            'image .' 1.5rem
            'image content'
            'image .' minmax(1.5rem, 3fr) /
            minmax(min-content, 3fr) 2fr;

          padding-left: ${spaceLarge};
          padding-right: ${spaceSmall};

          @media ${BREAKPOINTS.XL.max} {
            padding-left: 0;
            padding-right: ${minSpace};
          }
        `
      : css`
          grid-template:
            '.       image' minmax(2rem, 4fr)
            'icon    image'
            'title   image'
            '.       image' 1.5rem
            'content image'
            '.       image' minmax(1.5rem, 3fr) /
            2fr minmax(min-content, 3fr);

          padding-left: ${spaceSmall};
          padding-right: ${spaceLarge};

          @media ${BREAKPOINTS.XL.max} {
            padding-left: ${minSpace};
            padding-right: 0;
          }
        `}

  @media ${BREAKPOINTS.XL.max} {
    column-gap: 0.5rem;
    overflow: hidden;
  }

  @media ${BREAKPOINTS.LG.max} {
    margin-bottom: unset;
  }

  @media ${BREAKPOINTS.MD.max} {
    ${({ titlePlacement = 'below' }) =>
      titlePlacement === 'above'
        ? css`
            grid-template:
              '.' 1.5rem
              'icon' auto
              'title' auto
              '.' 1rem
              'image' auto
              '.' 1rem
              'content' auto;
          `
        : css`
            grid-template:
              'image' auto
              'icon' auto
              'title' auto
              '.' 1rem
              'content' auto;
          `}

    grid-gap: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;
    min-height: unset;

    ${({ backgroundColor = 'transparent', width, titlePlacement, fillMobileBackground = false }) =>
      titlePlacement === 'above'
        ? null
        : css`
        background: linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor}) no-repeat 0 ${
            !fillMobileBackground ? getClipHeight(width) : 0
          }px};
      `}
  }
`

export const SIcon = styled.div`
  grid-area: icon;
  margin-bottom: 1.5rem;

  @media ${BREAKPOINTS.MD.max} {
    justify-self: center;
    padding: 0 ${minSpace};
    margin-bottom: 1rem;
  }
`

export const SContent = styled(Stack)`
  grid-area: content;

  @media ${BREAKPOINTS.MD.max} {
    padding: 0 ${minSpace};
  }
`

export const SImage = styled.div<{
  imagePlacement: 'left' | 'right'
  centerMobileImage?: boolean
  imageOverflow?: boolean
}>`
  grid-area: image;
  position: relative;
  align-self: end;
  margin-bottom: ${({ imageOverflow }) => (imageOverflow ? '-2rem' : 0)};

  > div {
    object-fit: contain;
    object-position: top left;
    height: 100%;
  }

  @media ${BREAKPOINTS.XL.max} {
    margin-bottom: 0;
  }
  
  @media ${BREAKPOINTS.LG.max} {
    > div {
      position: absolute;
      top: 0;
      ${({ imagePlacement }) => (imagePlacement === 'left' ? `right: 0;` : `left: 0;`)}
      height: 100%;
      width: 100%;
    }
  }

  @media ${BREAKPOINTS.MD.max} {
    > div {
      position: relative;
      height: unset;
      width: unset;

      ${({ centerMobileImage }) =>
        centerMobileImage &&
        css`
          max-width: ${pxToRemUnit(500)};
          left: 50%;
          transform: translate(-50%, 0);
        `}
  }
`

export const SMediaArea = styled.div`
  grid-area: media;
`
