import styled from '@emotion/styled'
import { Button } from 'components/button/Button'

export const SVideoButton = styled(Button)`
  padding: 0.25rem 1rem;
  border-radius: 2px;
  border: none;
  background-color: var(--c-gray100);
  opacity: 0.9;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--c-gray100);
  }
`
