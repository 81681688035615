import { ContentfulVideoSlug } from 'api/contentful/slugs/video'
import { ContentfulImage, ContentfulImageProps } from 'components/Image'
import { VideoButton } from 'components/videoButton/VideoButton'
import React, { ReactElement, ReactNode, useMemo } from 'react'
import { useWindowSize } from 'utils/hooks'
import { SContainer, SContent, SIcon, SImage } from './LandingPageTextImageSection.styled'

export function LandingPageTextImageSection(props: {
  children: ReactNode
  title: ReactElement
  icon?: ReactElement
  image?: ContentfulImageProps | null
  mobileImage?: ContentfulImageProps | null
  imagePlacement?: 'left' | 'right'
  titlePlacement?: 'above' | 'below'
  backgroundColor?: string
  className?: string
  imageOverflow?: boolean
  centerMobileImage?: boolean
  fillMobileBackground?: boolean
  videoButton?: ContentfulVideoSlug
}) {
  const {
    children,
    className,
    title,
    icon,
    image,
    mobileImage,
    imagePlacement = 'right',
    titlePlacement = 'below',
    backgroundColor,
    imageOverflow,
    centerMobileImage,
    fillMobileBackground,
    videoButton,
  } = props
  const { isDesktop, width = 360 } = useWindowSize()
  const sectionImage = useMemo(
    () => (isDesktop ? image : mobileImage || image),
    [isDesktop, image, mobileImage]
  )
  const titleElm = React.cloneElement(title, { className: 'title' })
  return (
    <SContainer
      className={className}
      backgroundColor={backgroundColor}
      imagePlacement={imagePlacement}
      titlePlacement={icon ? 'above' : titlePlacement}
      width={width}
      imageOverflow={imageOverflow ?? false}
      fillMobileBackground={fillMobileBackground || centerMobileImage}
    >
      {titleElm}
      <SIcon>{icon}</SIcon>
      <SContent space="1.5rem">{children}</SContent>
      <SImage
        imagePlacement={imagePlacement}
        centerMobileImage={centerMobileImage}
        imageOverflow={imageOverflow}
      >
        <ContentfulImage {...sectionImage} objectFit="cover" />
        {videoButton && <VideoButton videoButtonType={videoButton} />}
      </SImage>
    </SContainer>
  )
}
