import { css } from '@emotion/react'
import { Icon } from 'components/icon'
import { HStack, Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { ReactNode } from 'react'
import { colors } from 'utils/colors'
import { pxToRemUnit } from 'utils/styled'

function LandingPageSectionPerk(props: { perk: ReactNode; iconColor?: string; simple?: boolean }) {
  const { perk, iconColor = colors.green[200] } = props

  return (
    <HStack
      space={pxToRemUnit(10)}
      css={css`
        display: flex;
        align-items: start;
        text-align: left;
      `}
    >
      <Icon
        name={props.simple ? 'CheckSimple' : 'CheckBold'}
        size="1em"
        color={iconColor}
        css={css`
          flex-shrink: 0;
          height: 1.75em; // simulate line-height: 175%;
        `}
      />
      <Text variant={'body'} color="black">
        {perk}
      </Text>
    </HStack>
  )
}

export function LandingPageTextImagePerks(props: {
  perks: ReactNode[]
  iconColor?: string
  className?: string
  simple?: boolean
}) {
  return (
    <Stack space={pxToRemUnit(10)} className={props?.className}>
      {props.perks.map((perk, i) => (
        <LandingPageSectionPerk
          key={i}
          perk={perk}
          iconColor={props.iconColor}
          simple={props.simple}
        />
      ))}
    </Stack>
  )
}
