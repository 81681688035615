import styled from '@emotion/styled'
import { ContentfulVideoSlug } from 'api/contentful/slugs/video'
import { Icon } from 'components/icon'
import { useVideoModal } from 'components/modal/VideoModal'
import { HStack, Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { colors } from 'utils/colors'
import { useContentfulVideos } from 'utils/contentful/ContentfulVideosContext'
import { BREAKPOINTS } from 'utils/styled'
import { SVideoButton } from './VideoButton.styled'

const SHStack = styled(HStack)`
  position: absolute;
  bottom: 1.5rem;
`

export const VideoButton = ({ videoButtonType }: { videoButtonType: ContentfulVideoSlug }) => {
  const videos = useContentfulVideos()
  const videoId = videoButtonType && videos[videoButtonType]

  const { t: tEstate } = useTranslation('estate_planning_lp')
  const { t: tDigital } = useTranslation('digital_protection')
  const { t: tWill } = useTranslation('will_lp')
  const { t: tTrust } = useTranslation('trust_lp')

  let text1, text2
  switch (videoButtonType) {
    case 'estate_planning':
      text1 = tEstate('estate_planning_lp.hero.video_button_first_line')
      text2 = tEstate('estate_planning_lp.hero.video_button_second_line')
      break
    case 'digital_protection':
      text1 = tDigital('digital_protection.hero.video_button_first_line')
      text2 = tDigital('digital_protection.hero.video_button_second_line')
      break
    case 'will':
      text1 = tWill('will_lp.hero.video_button_first_line')
      text2 = tWill('will_lp.hero.video_button_second_line')
      break
    case 'trust':
      text1 = tTrust('trust_lp.hero.video_button_first_line')
      text2 = tTrust('trust_lp.hero.video_button_second_line')
      break
    default:
      text1 = tEstate('estate_planning_lp.hero.video_button_first_line')
      text2 = tEstate('estate_planning_lp.hero.video_button_second_line')
      break
  }

  const [videoModal, openVideoModal] = useVideoModal(videoId)

  if (!videoButtonType) return null

  return (
    <div>
      {videoModal}
      <SHStack
        css={[
          { right: '-1.5rem' },
          BREAKPOINTS.XL.css({
            right: '1.5rem',
          }),
        ]}
      >
        <SVideoButton
          customColor={colors.white}
          variant="primary"
          type="button"
          size="large"
          leftIcon={<Icon name="Play" color={colors.white} />}
          onClick={openVideoModal}
          loading={false}
        >
          <Stack css={{ alignItems: 'flex-start' }}>
            <Text color={colors.white} variant="bodyBold">
              {text1}
            </Text>
            {text2 && (
              <Text color={colors.white} variant="bodySmall">
                {text2}
              </Text>
            )}
          </Stack>
        </SVideoButton>
      </SHStack>
    </div>
  )
}
